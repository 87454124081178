<template>
	<div class="members">
		<SectionHeader :image="getImage" title="Mitglieder" :page="title" />
		<section class="section">
			<div class="container">
				<div class="row g-3">
					<MemberList v-for="member in members" :key="member.id">
						<MemberItem :member="member" />
					</MemberList>
				</div>
			</div>
		</section>
	</div>
	<router-view></router-view>
</template>

<script>
import _ from 'lodash'
import MemberList from '@/views/MemberList.vue'
import MemberItem from '@/views/MemberItem.vue'
import SectionHeader from '@/views/SectionHeader'
	export default {
	name: "Mitglieder",
	components: {
		MemberList,
		MemberItem,
		SectionHeader,
	},
	methods: {},
	computed: {
		members() {
			let type = this.$route.params.type;
			switch(type)
			{
				case "vorstand":
					return _.orderBy(this.$store.state.members.filter(q => q.type == "1" || q.type == "2"), ['type', 'asc'], ['name', 'asc']);
				case "beisitzer":
					return _.sortBy(this.$store.state.members.filter(q => q.type == "3" || q.type == "4" || q.type == "5"), ['type', 'asc'], ['name', 'asc']);
				case "memoriam":
					return _.sortBy(this.$store.state.members.filter(q => q.type == "7"), ['name', 'asc']);
				default: 
					return _.orderBy(this.$store.state.members, 'type', 'asc');
			}
		},
		title() {
			let type = this.$route.params.type;
			if(type == "vorstand")
				return "Vorstand";
			else if(type == "beisitzer")
				return "Erweiterter Vorstand";
			else if(type == "memoriam")
				return "In ewiger Erinnerung";
			else
				return "Alle Mitglieder";
		},
		getImage() {
			let type = this.$route.params.type;
			if(type == "vorstand")
				return "70713ec4-f5e5-4516-9979-d150f809937f";
			else
				return null;
		},
		error() {
			return this.$store.state.loadingError;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
	created() {
		this.$store.dispatch('loadMembers');
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>