<template>
	<div class="card shadow-hover link-effect-box hover-box-opacity">
		<div class="position-relative">
			<img :src="getImage(member)" class="card-img-top">
			<div v-if="member.joined" class="position-absolute bg-success py-2 px-3 top-0 start-0 mt-3 ms-3 rounded-3 shadow text-white font-w-500 small hover-box-opacity-in">
				<i class="bi bi-calendar-date me-2"></i> <span v-text="getDate(member)"></span>
			</div>
		</div>
		<div class="card-body">
			<div class="nav mb-2">
				<div class="nav tag-style">
					<router-link v-if="member.group1" :to="{ name: 'Arbeitsgruppe', params: { slug: member.group1.slug } }" v-text="member.group1.post.title" class="text-reset"></router-link>
					<router-link v-if="member.group2" :to="{ name: 'Arbeitsgruppe', params: { slug: member.group2.slug } }" v-text="member.group2.post.title" class="text-reset"></router-link>
				</div>
			</div>
			<h4 class="h5 mb-3">
				<router-link :to="{ name: 'Mitglied', params: { id: member.id } }" class="text-reset link-effect-in" v-text="member.name" />
			</h4>
			<p class="m-0 beschreibung" v-if="member.description" v-text="member.description"></p>
		</div>
	</div>
</template>
<script>
import moment from 'moment'
export default {
	name: 'MemberItem',
	props: ['member'],
	methods: {
		getImage(member)
		{
			if(member)
			{
				return (member.picture) ? `https://api.bs-rt.de/assets/${member.picture.id}?key=thumbnail-300` : 'https://via.placeholder.com/300?text=Kein+Bild+vorhanden';
			}
		},
		getDate(member)
		{
			if(member)
			{
				return (member.joined) ? moment(member.joined).format('MMMM YYYY') : null;
			}
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}
};
</script>
<style scoped>
p:first-letter {
	text-transform:capitalize;
}
p.beschreibung {
	text-align: justify;
}
</style>